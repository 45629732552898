<template>
  <b-card-code>
   <validation-observer ref="simple">
    <!-- <div ref="form" > -->
      <b-row  v-for="(item, index) in Servicereports" :key="index"  class="repeater-form" :style="{ height: trHeight }">

              <b-col xl="4">

                <validation-provider #default="{ errors }" name="date" rules="required">
                  <b-form-group
                    label-for="date"
                    :state="errors.length > 0 ? false : null"
                  >
                          <label>تاريخ التواصل</label>
                    <flat-pickr
                      v-model="item.track_date"
                      class="form-control"
                      placeholder="حدد تاريخ التواصل"
                      :config="{ maxDate: maxDate }"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col xl="12">
                <validation-provider #default="{ errors }" name="note" rules="required">
                <b-form-group
                    label-for="note"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label>الملاحظات</label>
                    <!-- <b-form-textarea
                      id="textarea-default"
                      v-model="item.track_notes"
                      placeholder="الملاحظات"
                      rows="2"
                    /> -->
                    <div class="message-editor">
                        <vue-editor
                          id="quil-content"
                          v-model="item.track_notes"
                          :options="editorOption"
                          placeholder="الملاحظات"
                        />

                      </div>
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>

              </b-col>

              <!---->

                <div class="">
                   <b-col md="2" xl="2" v-if="index != 0">
                <b-button  v-ripple.400="'rgba(255, 255, 255, 0.15)'" lass="fixed-bottom"
                variant="outline-primary" @click="removeReport(index)">
                  <span class="align-middle"> إلغاء </span>
                </b-button></b-col>
                </div>
                <br>
                <br>
              <!--  -->

            </b-row>

          </validation-observer>
<!--
      </b-row>
    </div> -->
    <b-row>
      <b-col class="border-Primary" xl="2">
        <b-button
                variant="purple"
                @click="addNewItemInItemForm"
              >
                      <span class="align-middle"> إضافة تقرير</span>
              </b-button>
              </b-col>
             </b-row>
             <hr>
              <b-row>
                   <b-col xl="4"></b-col>
      <b-col class="border-Primary" xl="2">
        <b-button variant="purple" @click="ContinueStatus">
          <span class="align-middle"> حفظ</span>
        </b-button>
      </b-col>
      <b-col xl="4"></b-col>
    </b-row>
  </b-card-code>
</template>

<script>
import "flatpickr/dist/flatpickr.css";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

import { heightTransition } from "@core/mixins/ui/transition";
import BCardCode from "@core/components/b-card-code";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BFormRating,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BButton,
  BFormInvalidFeedback,
  VBToggle
} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
import { VueEditor } from "vue2-editor";
export default {
  mixins: [heightTransition],

  components: {
    BCardCode,
    VueEditor,
    VBToggle,
    BFormInvalidFeedback,
    BFormRating,
    BCardText,
    flatPickr,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    ToastificationContent,
    BButton,
    ValidationProvider,
    ValidationObserver,
    required,
    quillEditor
  },

  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth());
    return {
      value: null,
      value2: 2.67,
      connectDate: "",
      reportNotes: "",
      maxDate,
    quillConfig: {
        modules: {
          toolbar: [
            [{ direction: "rtl" }],
            ["bold", "italic", "strike"],
            // [{ header: 1 }, { header: 2 }],
            [{ list: "bullet" }, { list: "ordered" }],
            // [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            // [{ size: ["small", false, "large", "huge"] }],
            // [{ header: [1, 2, 3, 4, 5, 6, false] }],
            // [{ font: [] }],
            // [{ color: [] }, { background: [] }],
            ["image", "video"],
            [{ align: [] }],
            ["fullscreen"]
            // ["clean"]
          ]
        },
        placeholder: "add content",
        theme: "snow"
      },
      Servicereports: [
        {
         track_date: "",
          track_notes: "",
        },
      ],
    };
  },

  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },

  created() {
    window.addEventListener("resize", this.initTrHeight);
  },

  mounted() {
    this.initTrHeight();
  },

  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },

  methods: {
    removeReport(index) {
      this.Servicereports.splice(index, 1);
    },
        addNewItemInItemForm() {

      this.Servicereports.push(
        {
          track_date: "",
          track_notes: "",
        },
      ),

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    ContinueStatus() {
      this.$refs.simple
          .validate()
          .then((success) => {
          return new Promise((resolve, reject) => {

      let data = {
      tracks:this.Servicereports
      };
      //console.log(data);
      let url = `/api/v1/service_management/${this.$route.params.id}/trackings`;

      this.$http.post(url, data).then((res) => {
        //console.log(res);
            this.$emit("getServicTrackings");
             this.Servicereports=[
        {
          track_date: "",
          track_notes: "",
        },]
         requestAnimationFrame(() => {
                  this.$refs.simple.reset();
                });
        this.$swal({
          title: "تم إضافة المعلومات للحالة بنجاح",
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
          })})
    },
  }
};
</script>

<style scoped>
.but{
  margin-top: 50px;
}
.btn-purple {
  color: white;
  background-color: #00468e !important;
}
</style>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/quill.scss';
    </style>
